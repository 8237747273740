<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'Logout',
  mounted () {
    this.$auth.logout()
  }
}
</script>

<style lang="scss"></style>
